import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faPhone, faEnvelope, faMapMarker } from '@fortawesome/free-solid-svg-icons'
import logo from '../images/logo.png';

const Header = ({email, contactNumber, address}) => {

  return (
    <header>
      <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark" fixed="top">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="CSL Cleaning Services" width="50" height="50" className="float-left d-none d-sm-block" />
            <div className="ml-2 pt-2 float-left">
              <div className="heading">CSL Cleaning Services</div>
              <div className="sub-heading text-warning">Your contract cleaning specialists</div>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle>
            <FontAwesomeIcon icon={faBars} />
          </Navbar.Toggle>
          <Navbar.Collapse id="main-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/steam-cleaning">Steam Cleaning</Nav.Link>
              <Nav.Link href="/carpet-cleaning">Carpet Cleaning</Nav.Link>
              <Nav.Link href="/end-of-tenancy">End Of Tenancy</Nav.Link>
            </Nav>
            <div className="navbar-text">
              <div><FontAwesomeIcon icon={faPhone} /><a className="ml-2" href={`tel:` + contactNumber}>{contactNumber}</a></div>
              <div><FontAwesomeIcon icon={faEnvelope} /><a className="ml-2" href={`mailto:` + email}>{email}</a></div>
              <div className="hidden-sm-down"><FontAwesomeIcon icon={faMapMarker} /><span className="ml-2 text-light">{address}</span></div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header