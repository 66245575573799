import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone, faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'

import Header from "./header"

import '../sass/app.scss'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          email
          contactNumber
          facebookUrl
          address
        }
      }
    }
  `)

  return (
    <>
      <div className="site">
        <Header email={data.site.siteMetadata.email} contactNumber={data.site.siteMetadata.contactNumber} address={data.site.siteMetadata.address} />
        <div id="main" className="pt-5 pb-3 bg-light">{children}</div>
        <footer className="pt-4 pb-2 bg-secondary text-white">
          <Container>
            <Row>
              <Col>
                <div>
                  <FontAwesomeIcon icon={faMapMarker} className="mr-2" />
                  { data.site.siteMetadata.address }
                </div>
                <div>
                  <a className="text-white" href={`mailto:` + data.site.siteMetadata.email}>
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                    { data.site.siteMetadata.email }
                  </a>
                </div>
                <div>
                  <a className="text-white" href={`tel:` + data.site.siteMetadata.contactNumber}>
                    <FontAwesomeIcon icon={faPhone} className="mr-2" />
                    { data.site.siteMetadata.contactNumber }
                  </a>
                </div>
              </Col>
              <Col md={6} className="text-md-right my-1">
                {/* <small className="font-weight-light">Images by Markus Spiske on Unsplash</small> */}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="text-center">
                <a className="text-white mx-1" href={data.site.siteMetadata.facebookUrl}>
                  <FontAwesomeIcon icon={faFacebookSquare} size="lg" />
                </a>
                <a className="text-white mx-1" href={data.site.siteMetadata.yellUrl}>
                  <svg xmlns="http://www.w3.org/2000/svg"
                      width="25" height="25" viewBox="0 0 500 500">
                    <path id="yellow-pages" fill="#ffffff" stroke="none"
                          d="M 101.62,98.15
                            C 156.94,177.08 164.35,197.92 103.01,268.75
                              103.01,268.75 116.20,281.02 124.77,281.48
                              133.33,281.94 143.06,279.86 147.69,275.46
                              147.69,275.46 162.50,264.35 179.17,246.30
                              195.83,228.24 231.94,229.17 249.54,254.63
                              249.54,254.63 346.30,391.67 346.30,391.67
                              346.30,391.67 358.80,410.65 371.30,411.11
                              383.80,411.57 400.33,406.04 398.15,401.39
                              398.15,401.39 280.09,153.24 280.09,153.24
                              268.52,128.70 245.83,104.63 204.17,97.69
                              204.17,97.69 101.62,98.15 101.62,98.15 Z
                            M 201.08,249.23
                            C 213.19,245.14 229.50,248.86 239.35,264.69
                              242.97,271.25 244.44,279.32 239.81,288.73
                              239.81,288.73 165.28,413.73 165.28,413.73
                              152.31,404.48 133.49,385.65 149.85,356.64
                              149.85,356.64 201.08,249.23 201.08,249.23 Z" />
                  </svg>
                </a>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="text-center">&copy; 2020</Col>
            </Row>
          </Container>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout